<script setup lang="ts">
import { formatTimeAgo } from "@vueuse/core";
import type { Notification } from "~/types";

const { isGarnmentSlideoverOpen } = useDashboard();
import { animations } from "@formkit/drag-and-drop";

import { dragAndDrop } from "@formkit/drag-and-drop/vue";
import {
  parents,
  parentValues,
  dragValues,
  setParentValues,
  handleEnd,
} from "@formkit/drag-and-drop";
import { handleDragstart, handleTouchstart } from "@formkit/drag-and-drop";

const { uploadImage, deleteImage, images } = useFile();

function sourceTransfer(state, data) {
  const draggedValues = dragValues(state);

  const lastParentValues = parentValues(
    state.lastParent.el,
    state.lastParent.data
  ).filter((x) => !draggedValues.includes(x));

  setParentValues(state.lastParent.el, state.lastParent.data, lastParentValues);
}
const dropZoneRef = ref<HTMLElement>();
const fileInput = ref<HTMLInputElement>();
const { isOverDropZone } = useDropZone(dropZoneRef, onDrop);

async function onDrop(files) {
  files && (await uploadFile(files[0]));
}

async function uploadFile(file: File) {
  uploadingImg.value = true;

  await uploadImage(file)
    .catch(() =>
      toast.add({
        title: "An error occured",
        description: "Please try again",
        color: "red",
      })
    )
    .finally(() => (uploadingImg.value = false));
}

function openFilePicker() {
  fileInput.value?.click();
}

const sourceClone = (parent) => {
  const parentData = parents.get(parent);

  if (!parentData) return;

  return {
    setup() {
      parentData.config.performTransfer = sourceTransfer;
    },
  };
};

/*const [parent, garments] = useDragAndDrop([], {
  plugins: [animations()],
  group: "cards",
  handleDragstart: function () {
    isGarnmentSlideoverOpen.value = false;
  },
});*/

const garments = ref([
  {
    id: 1,
    imageUrl: "/jacket2.png",
    name: "Stylish Jacket",
    price: "$99.99",
    key: "jacket",
  },
  {
    id: 2,
    imageUrl: "/armani.png",
    name: "Casual Shirt",
    price: "$49.99",
    key: "shirt",
  },
  {
    id: 3,
    imageUrl: "/armani3.png",
    name: "Elegant Dress",
    price: "$129.99",
    key: "armani",
  },
  {
    id: 4,
    imageUrl: "/shirt3.png",
    name: "Shirt Stripe",
    price: "$79.99",
    key: "shirts",
  },
]);
const listRef = ref();

const initializeDragAndDrop = () => {
  dragAndDrop({
    parent: listRef,
    values: garments,
    group: "garments",
    // sortable: true,
    // dropZone: true,
    // dropZoneClass: "dragging",
    // draggingClass: "dragfly",
    plugins: [animations(), sourceClone],
    handleDragstart: function (event) {
      isGarnmentSlideoverOpen.value = false;
      handleDragstart(event);
    },
    handleTouchstart: function (event) {
      isGarnmentSlideoverOpen.value = false;
      handleTouchstart(event);
    },
    /* handleEnd: function (event) {
      console.log("hello5555");
      console.log(event);
      handleEnd(event);
    },*/
    draggable: (el) => {
      return el.id !== "no-drag";
    },
  });
};

// Watch for Slideover open state changes
watch(isGarnmentSlideoverOpen, (newVal) => {
  if (newVal) {
    // Reinitialize drag and drop when Slideover is opened
    initializeDragAndDrop();
  }
});

//garments.value = garmentsdata.value;

const { data: notifications } = await useFetch<Notification[]>(
  "/api/notifications"
);

/*function handleDragstart(index) {
  console.log("dragstart", index);
  isGarnmentSlideoverOpen.value = false;
}*/
</script>

<template>
  <UDashboardSlideover
    :ui="{ background: 'dark:bg-[#28282a]' }"
    v-model="isGarnmentSlideoverOpen"
    title="Garnments"
  >
    <div class="container mx-auto py-8">
      <div
        class="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3"
        ref="listRef"
      >
        <UCard
          v-for="garment in garments"
          :key="garment.key"
          :ui="{
            background: 'dark:bg-[#1e1f21]',
            ring: 'ring-0',
            base: 'rounded-lg overflow-hidden',
            body: 'px-0 py-0',
          }"
        >
          <!-- Photo of the garment -->
          <div class="h-40">
            <img
              draggable="false"
              :src="garment.imageUrl"
              alt="Garment Photo"
              class="object-cover w-full h-full p-0 dark:bg-[#1e1f21]"
            />
          </div>

          <!-- Garment data in the footer -->
          <template #footer>
            <div class="p-0">
              <p class="text-gray-600 text-xs">{{ garment.price }}</p>
              <h3 class="text-[9px] font-semibold">{{ garment.name }}</h3>
            </div>
          </template>
        </UCard>
        <div ref="dropZoneRef" id="no-drag">
          <input
            ref="fileInput"
            class="hidden"
            type="file"
            accept="image/*"
            @change="fileSelection"
          />
          <UploadButton2
            :uploading="uploadingImg"
            type="submit"
            class="mb-2"
            :is-over-drop-zone="isOverDropZone"
            @click="openFilePicker"
          />
        </div>
      </div>
    </div>
  </UDashboardSlideover>
</template>

<style>
.dragging {
  border: 2px dashed #4b5563 !important;
  background-color: #2d3748 !important;
  border-radius: 0.375rem !important;
  transition: all 0.2s ease !important;
}
.dragfly {
  transform: scale(1.25) !important;
  transition: all 0.2s ease !important;
}
</style>
